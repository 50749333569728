import React, { useEffect } from "react"
import {
    ResourceFilters,
    ResourcePager,
    SearchBar,
    Layout,
    CenteredLayout,
    Loader,
} from "@components"
import { useResources } from "../common/hooks"
import { getResources } from "@services"
import { sortByDate } from "@utilities"
import { RESOURCE_FIELDS, SORT_DIRECTION } from "../common/constants"
import scholarsImage from "../images/people.jpg"

const CommunityScholarProgram = () => {
    const {
        resourcesStates,
        handleInitialState,
        handleFilters,
        handleSearch
    } = useResources();

    useEffect(() => {
        const loadResources = async () => {
            const resources = await getResources("community-scholar")
            const sortedResources = sortByDate(
                resources,
                RESOURCE_FIELDS.lastModifiedDateTime,
                SORT_DIRECTION.desc
            )
            handleInitialState(sortedResources)
        }
        loadResources()
    }, [])

    return (
        <Layout>
            <div className="post-container p-8">
                <div className="blog-post">
                    <div className="bg-cover bg-center -m-8 mb-6" style={{ backgroundImage: `url(${scholarsImage})` }}>
                        <div className="bg-black bg-opacity-50 h-full w-full flex py-24 px-8">
                            <h1 className="text-white">Northwell Community Scholars</h1>
                        </div>
                    </div>
                    <div
                        className="blog-post-content"
                    />
                    <p>Welcome scholar!</p>
                    <p>Here you will find general program information and all the resources available to you through
                        Northwell Community Scholars (NCS).</p>
                    <h3>Program Overview</h3>
                    <p>NCS is an innovative program launched in 2021 by Northwell Health in
                        collaboration with select school districts throughout Northwell Health’s service area. The program
                        offers accepted high school students opportunities for academic advancement and career exploration
                        leading to potential employment. NCS provides participating students with services to support continued
                        growth and development including but not limited to socioemotional support, mentorship, college preparation, career advisement,
                        internship/shadowing opportunities, and financial support to pursue an associate degree or certificate
                        program at a collaborating institution of higher education.</p>
                    <p>The program is designed to:
                        <ul>
                            <li>Pair participating students 1:1 with a Northwell mentor who will guide them along their academic
                                and professional journeys.</li>
                            <li>Support students in successfully achieving high school graduation and continuing to pursue higher education.</li>
                            <li>Help students develop and strengthen their professional skills and competencies for entry into the workforce.</li>
                            <li>Foster student academic, professional, and personal success.</li>
                        </ul>
                    </p>
                    <h3>Eligibility and Application Process</h3>
                    <p>Applications open each winter for eligible freshman to apply. Selected applicants will begin the program in the fall of
                        their sophomore year.</p>
                    <p>At this time, NCS is collaborating with seven high schools:
                        <ul>
                            <li>A. Philip Randolph</li>
                            <li>Bay Shore</li>
                            <li>Brentwood</li>
                            <li>Freeport</li>
                            <li>Hempstead</li>
                            <li>Hillcrest</li>
                            <li>Uniondale</li>
                            </ul>
                    </p>
                    <p>Student eligibility criteria include:
                        <ul>
                            <li>A minimum grade average of 75.</li>
                            <li>A satisfactory attendance record, as defined by their school district.</li>
                            <li>An appropriate record of behavior in accordance with their school’s code of conduct.</li>
                            <li>Demonstrated financial need, which is defined as a household income of up to 500% of
                                the federal poverty level.</li>
                        </ul>
                    </p>
                    <p>To be considered for the program, eligible students must:
                        <ul>
                            <li>Submit an application, including a personal essay or video.</li>
                            <li>Provide a letter of recommendation.</li>
                            <li>Participate in an interview with the NCS Selection Committee.</li>
                        </ul>
                    </p>
                    <p>To remain in the program, students are expected to maintain a minimum GPA of 80 by the end of their sophomore year and
                        for the duration of their participation in the program.</p>
                    <h3>Scholarships</h3>
                    <p>Once they become high school seniors, students who remain in the program and maintain the program requirements become
                        eligible to receive a scholarship to pursue a two-year degree or certificate from a participating college. The scholarship
                        provides financial support for tuition, books, and other wraparound services such as food and transportation. To receive
                        the scholarship, students must be participating in the NCS program and accepted to and enrolled in classes fulltime at a
                        participating college.</p>
                    <p>To remain eligible to receive the scholarship students are expected to attend college classes full time, continue to
                        demonstrate financial need, and maintain a minimum GPA of 80 (3.0). Scholarships are renewable for up to 2 years based
                            on continued eligibility.</p>
                    <h3>Request for Expense Reimbursement</h3>
                    <p>If you are a currently a college scholarship recipient enrolled in a participating community college and have a qualifying
                        expense you would like to submit for reimbursement for,&nbsp;
                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=hW54f2ErvkunlnHpHl5-ODBqqf9IjapCrmqwYMzmVMRUNjZVS0I4T1FZQk03MFhCSTUyRklPOUFMOSQlQCN0PWcu"
                            target="_blank">
                            click here
                        </a>
                        &nbsp;to access the request form</p>
                    <p><strong>Important Note: All receipts and supporting documentation accompanying the Expense Reimbursement Form must be emailed to:
                        &nbsp;<a href="mailTo:ncs@northwell.edu">ncs@northwell.edu</a></strong></p>
                    <p>If you have any questions or concerns, please contact the NCS Team at <a href="mailTo:ncs@northwell.edu">ncs@northwell.edu</a></p>
                    <h3>Have any questions?</h3>
                    <p>Visit the FAQ <a href="/community-scholar-faq">here</a>.</p>
                    <h3>See program materials, media, and success stories below!</h3>
                    <p>Filter by your academic year and/or school to find relevant materials, or search by subject matter.</p>
                </div>
            </div>
            <div>
                <CenteredLayout className="bg-nwh-light-gray">
                    <SearchBar onSearch={handleSearch} className="mb-6" />
                    <ResourceFilters allResources={resourcesStates.allResources} filterKeys={resourcesStates.filterKeys} onChange={handleFilters} />
                </CenteredLayout>
                <CenteredLayout>
                    {!resourcesStates.isLoading && <ResourcePager data={resourcesStates.results} />}
                    {resourcesStates.isLoading && <Loader label="loading..." />}
                </CenteredLayout>
            </div>
        </Layout>
    )
}

export default CommunityScholarProgram
